<template>
  <div class="card-container" style="max-height: 95%">
    <div class="card-body card-modal pt-0">
      <h3 class="title ml-1">Filtro de operaciones</h3>
      <form class="p-1">
        <div v-if="isAdminOrStaffOrReseller">
          <label>Clientes</label>

          <v-select
            size="sm"
            :options="customers"
            label="fullText"
            v-model="item.customer"
            v-if="isAdminOrStaffOrReseller"
            class="style-chooser pb-1"
          />
        </div>
        <b-row>
          <b-col>
            <b-form-group label="Desde">
              <b-form-datepicker
                v-model="item.from"
                v-bind="{ value: 'es' }"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Hasta">
              <b-form-datepicker
                v-model="item.to"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Tipo">
          <v-select
            class="searchCustomer"
            :reduce="(v) => v.code"
            :options="types"
            v-model="item.type"
          />
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group label="Servicio de colecta">
              <v-select
                class="searchCustomer"
                :reduce="(v) => v.code"
                :options="collections"
                v-model="item.collection"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Servicio de entrega">
              <v-select
                class="searchCustomer"
                :reduce="(v) => v.code"
                :options="retirements"
                v-model="item.retirement"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Pago">
              <v-select
                class="searchCustomer"
                :reduce="(v) => v.code"
                :options="payments"
                v-model="item.status_payment"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Envio">
              <v-select
                class="searchCustomer"
                :reduce="(v) => v.code"
                :options="shippings"
                v-model="item.type_shipping"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-1">
          <button type="button" class="btn btn-outline-primary mr-1" @click="onClear">
            Limpiar
          </button>
          <button type="button" class="btn btn-primary" @click="_onFilter">
            Filtrar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import CustomerService from "@/services/CustomerService";
import OperationService from "@/services/OperationService";

export default {
  name: "FilterComponent",
  components: {},
  data() {
    return {
      customers: [],
      item: {
        customer: null,
        from: null,
        to: null,
      },
      isAdmin: UserService.isAdminOrStaff(),
      isAdminOrStaffOrReseller: UserService.isAdminOrStaffOrReseller(),
      types: [
        { code: null, label: "" },
        { code: "pre-pago", label: "pre-pago" },
        { code: "post-pago", label: "post-pago" },
      ],
      shippings: [
        { code: null, label: "" },
        { code: "shipping", label: "Envío" },
        { code: "change", label: "Cambio" },
        { code: "devolution", label: "Devolución" },
      ],
      payments: [
        { code: null, label: "" },
        { code: "PAGADO", label: "Pagado" },
        { code: "PENDIENTE", label: "Pendiente" },
        { code: "FACTURADA", label: "Facturada" },
        { code: "POR PAGO", label: "Pendiente por pago" },
        { code: "REINTEGRADO", label: "Reintegrado" },
      ],
      retirements: [
        { code: null, label: "" },
        { code: "client", label: "Domicilio" },
        { code: "point", label: "Punto de retiro" },
      ],
      collections: [
        { code: null, label: "" },
        { code: "client", label: "Cliente" },
        { code: "point", label: "Drop off" },
      ],
    };
  },
  methods: {
    _onFilter() {
      this.onFilter.handler(this.item);
      this.$emit("close");
    },
    onClear() {
      this.onFilter.handler({
        customer: null,
        from: null,
        to: null,
        clear: true,
      });
      this.$emit("close");
    },
    /**
     *
     */
    onAddFilter() {
      this.filters.push(this.filter);
    },
  },
  mounted() {
    /**
     *
     */
    CustomerService.index().then(({ data }) => {
      this.customers = data.data.map((d) => {
        return Object.assign(d, { fullText: d.brand, code: d.id });
      });
    });

    /**
     *
     */
    OperationService.statuses().then(({ data }) => {
      this.statuses.push({ value: null, text: "" });
      this.statuses = data.data.map((d) => {
        return Object.assign(d, { code: d.id, label: d.title });
      });
    });
    /**
     *
     */
    if (this.customer) {
      this.item.customer = this.customer;
      this.item.customer_id = this.item.customer.id;
    }

    /**
     *
     */
    if (this.from) {
      this.item.from = this.from;
    }

    /**
     *
     */
    if (this.to) {
      this.item.to = this.to;
    }

    /**
     *
     */
    if (this.type) {
      this.item.type = this.type;
    }

    /**
     *
     */
    if (this.status) {
      this.item.status = this.status;
    }

    /**
     *
     */
    if (this.retirement) {
      this.item.retirement = this.retirement;
    }

    /**
     *
     */
    if (this.collection) {
      this.item.collection = this.collection;
    }

    /**
     *
     */
    if (this.status_payment) {
      this.item.status_payment = this.status_payment;
    }

    /**
     *
     */
    if (this.type_shipping) {
      this.item.type_shipping = this.type_shipping;
    }
  },
  computed: {
    searchClient: function () {
      return this.filters.find((i) => i == "Cliente");
    },
    optionFilter: function () {
      const options = [
        "Cliente",
        "Fecha",
        "Tipo",
        "Status",
        "Servicio de colecta",
        "pago",
        "envio",
      ];
      return options.filter((d) => {
        return !this.filters.find((i) => i == d);
      });
    },
  },
  props: {
    onClose: Object,
    onFilter: Object,
    customer: null,
    customer_id: Object,
    from: Date,
    to: Date,
    type: String,
    status: Number,
    retirement: String,
    collection: String,
    status_payment: String,
    type_shipping: String,
  },
};
</script>
<style lang="scss" scoped>
.card-container{
  margin: 1.5rem;
}
.card-modal {
  height: 650px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .card-container{
    margin: 1rem;
  }
  .card-modal {
    height: auto;
    max-width: 95%;
  }
}

.elements {
  height: 550px;
  overflow-y: hidden;
}

.md-datepicker-dialog {
  z-index: 1040 !important;
}
</style>
